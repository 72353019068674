<!--  -->
<template>
  <div class="zhuchang">
    <img class="bg" src="../../images/rob/bg.png" alt="" srcset="" />
    <div class="head_NavBar">
      <van-icon name="arrow-left" size="20" color="#fff" @click="back()" />
      <div class="title">云展场惠民券</div>
    </div>
    <div class="row_cont">
      <div class="row_box">
        <div class="suiji">随机派发一张</div>
        <img class="z1" src="../../images/rob/z2.png" alt="" srcset="" />
        <van-field
          v-model="phoenData.tel"
          type="tel"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="phoenData.sms"
          center
          clearable
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              color="linear-gradient(to right, #0FBBF4, #0F91F9)"
              size="small"
              :disabled="isSms"
              @click="getSms()"
            >
              <span v-if="!isSms">发送验证码</span>
              <van-count-down @finish="finish" :time="time" v-if="isSms">
                <template #default="time">
                  <span class="djs">{{ time.seconds }}</span>
                </template>
              </van-count-down>
            </van-button>
          </template>
        </van-field>
        <div class="quangjuan_btn" @click="qiangFun()">立即抢券</div>
        <div class="zhuyi">*每个手机号限领一张哦</div>
      </div>
    </div>
    <van-overlay :show="tanshow" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="type type1" v-if="tanIndex == 1">
          <div class="box">
            <van-loading color="#0F91F9" size="17px">抢卷中...</van-loading>
          </div>
        </div>
        <div class="type type2" v-if="tanIndex == 2">
          <div class="box">
            <div class="text1">恭喜获得</div>
            <div class="text2">
              请查看规则后立即兑换哦
            </div>

            <div class="btn" @click="runlin()">查看用券规则</div>
          </div>
        </div>
        <div class="type type3" v-if="tanIndex == 3">
          <div class="box">
            <div class="text1">抢券失败</div>
            <div class="text2">
              您手速慢了哦，请明天再来！关注本公众号”预约领券“可获得更多机会
            </div>
            <router-link to="/">
              <div class="btn">返回展场首页</div>
            </router-link>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
  
  <script>
  
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Field, Icon, Button, Overlay, Loading, Toast, CountDown } from "vant";
export default {
  name: "zhuchang",
  //import引入的组件需要注入到对象中才能使用
  components: {
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Toast.name]: Toast,
    [CountDown.name]: CountDown,
  },
  data() {
    //这里存放数据
    return {
      tanshow: false,
      tanIndex: 1,
      phoenData: {
        tel: "",
        sms: "",
      },
      isSms: false,
      time: 60 * 1000,
      codeNum: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    back() {
      this.$router.back();
    },
    getSms() {
      var that = this;
      //console.log(that.phoenData);
      var myreg =
        /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if (!myreg.test(that.phoenData.tel)) {
        Toast("请输入有效的手机号码！");
        return false;
      } else {
        that.isSms = true;
        that.time = 60 * 1000;
        this.postRequest(
          "http://tfbookfairapi.symansbon.cn/GetVerificationCode/GetVcode",
          {
            Phones: that.phoenData.tel,
          }
        ).then((res) => {
          //console.log(res);
          if(res.state == 1){
            Toast('短信已发送注意查收')
          }else{
            that.isSms = false;
            that.time = 0;
            Toast(res.tip)
          }
         
        });
      }
    },
    //倒计时结束
    finish() {
      this.isSms = false;
    },
    qiangFun() {
      var that = this;
      if (that.phoenData.sms != "" && that.phoenData.tel != "") {
        that.tanshow = true;
        this.postRequest("http://tfbookfairapi.symansbon.cn/Order/PlaceOrder", {
          userName: that.phoenData.tel,
          userYzm: that.phoenData.sms,
        }).then((res) => {
          //console.log(res);
          that.tanIndex = 1;
          setTimeout(function () {
            if (res.state == 1) {
              that.tanIndex = 2;
              that.codeNum = res.tip;
            }
            if (res.state == 2) {
              that.tanIndex = 3;
              setTimeout(function () {
                that.tanshow = false
              },4000)
            }
            if (res.state == 3) {
              Toast(res.tip);
              setTimeout(function () {
                that.tanshow = false
              },1000)
             
            }
          }, 1000);
        });
      } else {
        Toast("请输入电话和验证码");
      }
    },
    runlin() {
      this.$router.push("/yunchangText?code=" + this.codeNum);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style scoped lang="less">
.zhuchang {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #ce3444;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.head_NavBar .title {
  color: #fff;
}
.row_cont {
  position: relative;
  width: 100%;
  padding: 0 27px;
  .row_box {
    width: 100%;
    background: #ffffff;
    border-radius: 15px 15px 15px 15px;
    padding: 45px 20px;
  }
}
.suiji {
  font-size: 17px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #0f91f9;
  text-align: center;
  margin-bottom: 20px;
}
.z1 {
  position: relative;
  width: 100%;
  margin-bottom: 35px;
}
.quangjuan_btn {
  width: 100%;
  height: 49px;
  background: #0f91f9;
  margin-top: 50px;
  border-radius: 24px 24px 24px 24px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zhuyi {
  font-size: 10px;
  font-weight: 500;
  color: #0f91f9;
  text-align: center;
  margin-top: 33px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.type {
  background: linear-gradient(180deg, #1090fa 0%, #0dbcf3 100%);
  border-radius: 5px 5px 5px 5px;
  padding: 8px;
}
.type1 {
  width: 169px;
  height: 99px;
  .box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.type2 {
  width: 182px;
  height: 210px;
  .box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #fee5eb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .text1 {
    font-size: 17px;
    font-weight: bold;
    color: #0f91f9;
  }
  .text2 {
    font-size: 12px;
    width: 150px;
    font-weight: 500;
    color: #0f91f9;
    margin-top: 20px;
    line-height: 1.6;
  }
  .btn {
    width: 125px;
    margin-top: 20px;
    height: 30px;
    background: #0f91f9;
    border-radius: 24px 24px 24px 24px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.type3 {
  width: 182px;
  height: 210px;
  .box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .text1 {
    font-size: 17px;
    font-weight: bold;
    color: #0f91f9;
  }
  .text2 {
    font-size: 8px;
    width: 150px;
    font-weight: 500;
    color: #666666;
    margin-top: 20px;
    line-height: 1.6;
  }
  .btn {
    width: 125px;
    margin-top: 20px;
    height: 30px;
    background: #0f91f9;
    border-radius: 24px 24px 24px 24px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.djs {
  color: #fff;
}
</style>